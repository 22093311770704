<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form ref="formData" :model="formData" label-width="80px" @submit.native.prevent>
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：实时库存明细-横向</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :sm="12" :md="1">
                                <el-checkbox style=" margin-top: 12px; " v-model="isAccurate" @change="findDetailWarehouse">精准匹配</el-checkbox>
                            </el-col>
                            <el-col :sm="12" :md="5">
                                <el-form-item label="类型(可多选):" label-width="120px">
                                    <el-select
                                            v-model="formData.category_name"
                                            style="margin-left: 2px;"
                                            size="mini"
                                            multiple
                                            collapse-tags
                                            filterable
                                            clearable
                                            default-first-option
                                            @change="isDataChanage=true"
                                            @clear="$_searchCategoryList"
                                            @focus="$_searchCategoryList"
                                            placeholder="可以搜索、可选多选">
                                        <el-option v-for="item in $store.getters.getCategoryList" :key="item.id" :label="item.name"
                                                   :value="item.name">
                                            <span style="margin:15px;">{{ item.name}}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :lg="2" >
                                <el-button style=" margin-top: 8px; " id="createBill" type="primary" round  @click="findDetailWarehouse" size="mini">搜索查询</el-button>
                            </el-col>
                            <el-col :lg="6" :sm="12" :md="8">
                                <el-row>
                                    <el-col class="pull-left" :lg="4">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; " custom print export
                                                     ref="xToolbar2">
                                        </vxe-toolbar>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                show-footer
                                ref="xGrid"
                                id="xGrid"
                                size="mini"
                                align="right"
                                :height="(this.getViewHeight() - 130)+''"
                                highlight-hover-row
                                highlight-current-row
                                :print-config="{}"
                                :custom-config="{storage:true}"
                                :export-config="{type: ['xlsx'] ,types: ['xlsx', 'csv', 'html', 'xml', 'txt'],isFooter:true,isColgroup:true}"
                                :footer-method="$_footerMethod"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :mouse-config="{selected: false}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,2500,5000,50000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>
                </el-row>
            </el-form>
        </el-main>
    </div>
</template>

<script>
    import XEUtils from 'xe-utils'
    import {filterRender} from "@/utils/gird-filter";


    export default {

        data() {
            return {
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 2500
               },
                tableColumn: [
                    {
                        field:'category_name', fixed: 'left',width: 75, title: '类型', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'specification_name',fixed: 'left', width: 120, title: '规格', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                       }
                   },
                    {
                        field:'a_side', width: 75, title: 'A边', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'b_side', width: 75, title: 'B边', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'thickness', width: 75, title: '厚度', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        field:'length_extent', width: 75, title: '长度', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                        }
                    },
                    {
                        width: 100, title: '总库存(支数)',
                        children: [
                            {
                                field:'single_count_total', width: 70, title: '总支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },
                            {
                                field:'single_lock', width: 70, title: '总锁定', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },{
                                field:'single_count_available', width: 70, title: '总可用', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           }
                        ]
                   },{
                        width: 100, title: '厂一',
                        children: [
                            {
                                field:'warehouse4_total', width: 70, title: '支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },
                            {
                                field:'warehouse4_lock', width: 70, title: '锁定', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },{
                                field:'warehouse4_available', width: 70, title: '可用', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           }
                        ]
                   },
                    {
                        width: 100, title: '厂二',
                        children: [
                            {
                                field:'warehouse5_total', width: 70, title: '支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },
                            {
                                field:'warehouse5_lock', width: 70, title: '锁定', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },{
                                field:'warehouse5_available', width: 70, title: '可用', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           }
                        ]
                   }, {
                        width: 100, title: '仓一',
                        children: [
                            {
                                field:'warehouse1_total', width: 70, title: '支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },
                            {
                                field:'warehouse1_lock', width: 70, title: '锁定', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },{
                                field:'warehouse1_available', width: 70, title: '可用', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           }
                        ]
                   }, {
                        width: 100, title: '仓二',
                        children: [
                            {
                                field:'warehouse2_total', width: 70, title: '支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },
                            {
                                field:'warehouse2_lock', width: 70, title: '锁定', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },{
                                field:'warehouse2_available', width: 70, title: '可用', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           }
                        ]
                   }
                    , {
                        width: 100, title: '仓三',
                        children: [
                            {
                                field:'warehouse3_total', width: 70, title: '支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },
                            {
                                field:'warehouse3_lock', width: 70, title: '锁定', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },{
                                field:'warehouse3_available', width: 70, title: '可用', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           }
                        ]
                   }, {
                        width: 100, title: '仓四',
                        children: [
                            {
                                field:'warehouse9_total', width: 70, title: '支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },
                            {
                                field:'warehouse9_lock', width: 70, title: '锁定', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },{
                                field:'warehouse9_available', width: 70, title: '可用', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           }
                        ]
                   }, {
                        width: 100, title: '新门市',
                        children: [
                            {
                                field:'warehouse7_total', width: 70, title: '支数', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },
                            {
                                field:'warehouse7_lock', width: 70, title: '锁定', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           },{
                                field:'warehouse7_available', width: 70, title: '可用', slots: {
                                    header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/detailWarehouseLengthWays/searchHeard', this.pickerDate)
                               }
                           }
                        ]
                   }
                ],
                tableDataMain: [],
                filterData: {},
                sortData: {
                    a_side:'asc' ,
                    b_side:'asc',
                    thickness:'asc',
                    length_extent:'asc',
                },
                start_date: '',
                end_date: '',
                specification: [],
                isAccurate:false,
                formData: {
                    category_name:''
                }
           }
       },
        methods: {
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.findDetailWarehouse()
           },
            findDetailWarehouse(){
                this.$axios({
                    method: 'POST',
                    url: '/admin/detailWarehouseLengthWays',
                    data:{
                        currentPage: this.tablePage.currentPage,
                        pageSize:this.tablePage.pageSize,
                        sortData:this.sortData,
                        filterData:this.filterData,
                        pickerDate:this.pickerDate,
                        formData: this.formData,
                        isAccurate:this.isAccurate
                   }
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.list
                        this.tablePage.pageSize = response.data.pageSize
                        this.tablePage.total = response.data.totalRow
                        this.tablePage.currentPage = response.data.pageNumber
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
            editMethod({row, column}) {
                const $table = this.$refs.xGrid
                // 重写默认的覆盖式，改为追加式
                $table.setActiveCell(row, column)
           },
            formatDate(value, format) {
                return XEUtils.toDateString(value, format)
           },
            tableRowClassName({rowIndex}) {
                if (rowIndex % 2 === 1) {
                    return 'success-row';
               } else {
                    return '';
               }
           },
            onSubmit() {
                //console.log('submit!');
           }
       },
        created() {
            this.loading = true
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
            this.findDetailWarehouse();
       }
   };


</script>

<style scoped>
    .header-wrapepr {
        display: flex;
        flex-direction: row;
        justcontent: space-between
   }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 130px;
   }

    .el-form-item__content .el-input {
        width: 130px;
   }
</style>
